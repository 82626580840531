/* eslint-disable no-console */
import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import jwt from  'jsonwebtoken'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {BaseURL, updatePasswordAdmin, MasterKeyLogin} from '../constants/constants'
//import axios from 'axios';

const title = {
  pageTitle: 'Recuperar contraseña',
};

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://nextpos.us/">
          NextPOS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "#1f2d3d",
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor : "#1f2d3d"
      },
      buttonProgress: {
        color: "#1f2d3d",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

//class ForgotPassword extends Component {
    export default function ResetPassword(props){
        const classes = useStyles();
        const [password, setPassword] = useState("");
        const [password2, setPassword2] = useState("");
        const [emptyPassword,setEmptyPassword]= useState(false)
        const [loader,setLoader]=useState(false)
        const [open, setOpen] =useState(false);
        const [msg, setMsgs]= useState("")
        let history = useHistory();
       let token = props.match.params.token
       
       let crypto_mail = props.match.params.mail
      
       var cipherformat2 = crypto_mail.replace("Por21Ld",/\//g)
      
       var decrypt_mail  = CryptoJS.AES.decrypt(cipherformat2, 'l061nu53r');
      
       var mail = decrypt_mail.toString(CryptoJS.enc.Utf8);

       
   
       console.log(mail)
       jwt.verify(token, 'secret key', function(err, user) {
        if (err) {
          console.log(err)
          history.push("/404");
       }
       })
    
       function resetPassword(event){
        if(password ===""|| password2 ===""){
          setEmptyPassword(true)
          return
         }
         if(password != password2){
          setEmptyPassword(false)
          handleClickOpen()
        }else{
          setEmptyPassword(false)
          setLoader(true)
          var cipherpassword = CryptoJS.AES.encrypt(password, "l061nu53r");
          var payload= {
            password : cipherpassword.toString(),
            mail: mail
          }
          axios.post(BaseURL+updatePasswordAdmin, payload)
          .then(function (response) {
            setLoader(false)
                if(response.data.Exito){
                  setMsgs(response.data.Message)
                  
                  handleClickOpen()
                  
                }
                else{   
                  setMsgs(response.data.Message)
                  handleClickOpen()
                }
               
          })
          .catch(function (error) {
            setLoader(false)
            setMsgs("Error en el servidor")
          });

        }
       }
       const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        document.getElementById("password1").value = ""
        document.getElementById("password2").value = ""
        
      };
    return (
      <React.Fragment>
<Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ¡Nueva Contraseña!
          </Typography>

        <form className={classes.form} >
          <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                      error = {emptyPassword}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="Contrasena1"
                      label="Contraseña"
                      type="password"
                      id="password1"
                      disabled = {loader}
                      onChange ={e => setPassword(e.target.value)}
                      helperText={emptyPassword?"Campo vacio":""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                          error = {emptyPassword}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="Contrasena2"
                          label="Confirmar Contraseña"
                          type="password"
                          id="password2"
                          disabled = {loader}
                          onChange ={e => setPassword2(e.target.value)}
                          helperText={emptyPassword?"Campo vacio":""}
                        />
                </Grid>
                <Grid item xs={12}>
                "La información mostrada en esta página es confidencial."
              </Grid>
            </Grid>
            <Button
              
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              //className={classes.submit}
              disabled = {loader}
              onClick={(event) => resetPassword(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Guardar
            </Button>

        </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
        </Container>
      </React.Fragment>
      
    );
  // render} 
}

//export default ForgotPassword;