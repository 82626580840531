import React from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import {BaseURL,getAllTransactions,getTransactionsByDate} from '../../../constants/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import PdfDocument from './PdfDocument';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { Filter, Sort } from '@material-ui/icons';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  
  buttonProgress: {
    color: "#000048",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  titleTable:{
    fontWeight:'bold'
  },

  successText:{
    color:'green'
  },
  pendingText:{
    color:'orange'
  },
  rejectText:{
    color : 'red'
  },
  submit: {
    color:"white",
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
    textTransform:"capitalize",
    width:"150px",
  },
  
}));
export default function Orders(props) {
  const classes = useStyles();
  let rifMerchant = props.rif
 
  const [open, setOpen] =React.useState(false);
  const [openDownloader, setOpenDownloader] = React.useState(false)
  const [openSearchByDate, setOpenSearchByDate] = React.useState(false)
  const [loader,setLoader] = React.useState(false)
  const [loadingPdf, setLoadingPdf] = React.useState("")
  const [dateError,setDateError] = React.useState(false)
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date());
  const [table, setStateTable]=React.useState({
    data: [
      //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
      //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
    ],
  })
React.useEffect((props) => {
  
  let rif = rifMerchant
  setLoader(true)
  axios.get(BaseURL+getAllTransactions+rifMerchant)
  
  .then(res => {
      setLoader(false)
      if (res.data[0] != undefined) {
         let datos  = res.data
         
         datos.forEach(function(e){
          if(e.estatus === 1){
            e["estatus"] = <a className ={classes.successText}>
                                  Aprobado
                           </a>
          }else if (e.estatus ===2){
            e["estatus"] = <a className ={classes.rejectText}>
                              Rechazado
                           </a> 
          }
         })
         setStateTable({data:datos})
          return res.data;
        } else {
          return 'Null';
        }

  })
  .catch(err=>{
    setLoader(false)
    console.log(err)
  })

},[])
const handleDateChangeTo = date =>{
  setSelectedDateTo(date)
};


const handleDateChangeFrom = date =>{
  setSelectedDateFrom(date)
};
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

// const exportCsv = (allColumns, allData) => {
//   const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
//   const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
//   new _filefy.CsvBuilder('filename_' + moment().format('YYYY-MM-DDTHHmmss'))
//     .setDelimeter(';')
//     .setColumns(columns.map(columnDef => columnDef.title))
//     .addRows(exportedData)
//     .exportFile();
// }

const getTransactionDate = ()=>{
  let to = document.getElementById("toTrx")
  let from = document.getElementById("fromTrx")
 
  let dateTrim= to.value+"-"+from.value
  let dateFormat = dateTrim.split("-")
  let toWithoutFormat = dateFormat[0].replace(/[/]/g," ")
  let fromWthoutFormat = dateFormat[1].replace(/[/]/g," ")
  
  if(selectedDateTo > selectedDateFrom){
    setDateError(true)
    return
  }
  
  handleClose()
  setLoader(true)
  axios.get(BaseURL+getTransactionsByDate+"?fecha="+toWithoutFormat+"-"+fromWthoutFormat+"&rif="+rifMerchant)
  .then(res => {
    console.log(res.data.message)
    setLoader(false)
    let datos  = res.data.message
    
    datos.forEach(function(e){
      if(e.estatus === 1){
        e["estatus"] = <a className ={classes.successText}>
                              Aprobado
                       </a>
      }else if (e.estatus ===2){
        e["estatus"] = <a className ={classes.rejectText}>
                          Rechazado
                       </a> 
      }

      e["aplicacion"] = e.aplicacion
     })
          setStateTable({data:datos})
         
          return res.data
      
  })
  .catch(err=>{
   
    setLoader(false)
    console.log(err)
  })
};

const handleOpenDowloader = ()=>{
  setOpenDownloader(true)
};

const handleCloseDownloader = ()=>{
  setOpenDownloader(false)
};
  return (
  
  <React.Fragment>   
       <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            Selecciona los criterios de filtrado y haz clic en el botón Buscar 
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="toTrx"
                  label="Desde"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  //error ={dateError}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="fromTrx"
                  label="Hasta"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            {/* <Buttons onClick = {getTransactionDate} color="primary">
              Ir
            </Buttons> */}
            <Button
                  fullWidth  
                  color="white"
                  className={classes.submit}
                  disabled ={loader}
                  onClick = {getTransactionDate}
                >
                  {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
                  Buscar
                </Button>
          </DialogActions>
        </Dialog>
                
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={openDownloader}
         
          onClose={handleClose}
          message="Descargar archivo"
          action={
            <React.Fragment>
              
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                {openDownloader &&<PDFDownloadLink
                document={<PdfDocument data={table} />}
                fileName="Reporte.pdf"
                style={{
                  textDecoration: "none",
                  padding: "5px",
                  color: "white",
                  backgroundColor: "transparent",
                  
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (<CircularProgress style={{height:20, width:20}} color="green" /> )  : (<GetAppIcon onClick= {handleCloseDownloader}/>)
            }
            </PDFDownloadLink>}
                
              </IconButton>
            </React.Fragment>
          }
      />
    <MaterialTable
      title={<Typography className = {classes.titleTable}>TRANSACCIONES</Typography>}
      isLoading = {loader}
      columns={
        [
          { title: 'Fecha', field: 'fecha' },
          { title: 'Monto Bs', field: 'monto' },
          { title: 'Nro Aprobación', field: 'aprobacion' },
          { title: 'Nro Referencia',field: 'num_referencia'},
          { title: 'Lote',field: 'lote'},
          { title: 'últimos 4 digitos tarjeta',field:'ultimos4_digitos'},
          { title: 'Usuario',field:'username'},
          // { title: 'Aplicación',field:'aplicacion'},
          { title: 'Estatus', field:'estatus'}
        ]
        
      }
      data={table.data}
      options={{
        headerStyle: {
          backgroundColor: '#000048',
          color: '#FFF'
        },
        exportButton: {
          csv: true,
          pdf: true,
       },
       sorting:true,
       
        
      }}
      actions={[
        {
          icon: DateRangeIcon,
          tooltip: 'Buscar fecha',
          isFreeAction: true,
          onClick: handleClickOpen,
        },
        { icon: PictureAsPdfIcon,
          tooltip: 'Descargar PDF',
          isFreeAction: true,
          onClick: handleOpenDowloader},
        {
          icon: Sort,
          tooltip: 'Ordenar',
          isFreeAction:true,
          onClick:Sort
        },
        
      ]}
    >
      
    </MaterialTable>
    </React.Fragment>
  );

  
}