import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from '../../Title';
import {BaseURL, SumTrxApi,SumTrxDate, MasterKeyLogin} from '../../../constants/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  wrap:{
    height:'100%',
    position: 'relative'
  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'relative',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    
  },
  balance:{
    position:'absolute',
    left:0,
    bottom:0
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function Deposits(props) {
  const classes = useStyles();
  let rifMerchant = props.rif
  let rif = rifMerchant
  var apiBaseUrl =BaseURL;
  var payload={
      "rif":rif    
  }


  
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date());
  const [dateError,setDateError] = React.useState(false)
  const [open, setOpen] =React.useState(false);
  const [loader,setLoader] = React.useState(false)
  const [table, setStateTable]=React.useState({
    data: [
      //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
      //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
    ],
  })
  let totalAmount;
  React.useEffect((props) => {
    let wrap = document.getElementById("wrap")
    wrap.style.opacity = 0.5
    setLoader(true)
    axios.post(apiBaseUrl+SumTrxApi,payload)
    .then(res => {
      wrap.style.opacity = 1
      setLoader(false)
      
      if(res.data.Exito){
        totalAmount = res.data.Sum
      }else{
        totalAmount = 0.00
      }
      setStateTable({data:totalAmount})
      
        
    })
    .catch(err=>{
      wrap.style.opacity = 1
      setLoader(false)
      console.log(err)
    })
  
  },[])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChangeTo = date =>{
    setSelectedDateTo(date)
  };

  const handleDateChangeFrom = date =>{
    setSelectedDateFrom(date)
  };

  const getTransactionDate = ()=>{
    let to = document.getElementById("to")
    let from = document.getElementById("from")
    
    if(selectedDateTo > selectedDateFrom){
      setDateError(true)
      return
    }
    console.log(to.value)
    let schema = {
      "rif":rif,
      "to":to.value,
      "from":from.value
    }
    
    handleClose()
    setLoader(true)
    axios.post(apiBaseUrl+SumTrxDate,schema)
    .then(res => {
      
      setLoader(false)
      totalAmount = res.data.Sum
    
      setStateTable({data:totalAmount})
        
    })
    .catch(err=>{
      
      setLoader(false)
      console.log(err)
    })
  };

  const handleCloseAlert = () =>{
    setDateError(false)
  };
  return (
    <React.Fragment  >
      <div className = {classes.root}>
            

                <Snackbar  open={dateError} autoHideDuration={6000} onClose={handleCloseAlert} TransitionComponent={Transition} >
                  <Alert variant="filled" severity="error">La fecha de inicio no puede ser mayor</Alert>
                </Snackbar>
         
      </div>
      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Buscar"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Seleccione el rango de fecha
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="to"
                  label="Desde"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error ={dateError}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="from"
                  label="Hasta"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={getTransactionDate} color="primary">
              Ir
            </Buttons>
          </DialogActions>
        </Dialog>

      <div className = {classes.wrap} id = "wrap">
      <Title>Total transacciones</Title>
      <Typography component="h2" variant="h4"  >
      { loader ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:"Bs " +table.data }
      </Typography>
     
      <div>
        <Link color="primary" className = {classes.balance} href="#" onClick={handleClickOpen}>
          Ver balance
        </Link>
      </div>
      </div>
    </React.Fragment>
  );
}
