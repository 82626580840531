import React , { useState }from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {BaseURL, addNewUserAdmin, MasterKeyLogin} from '../constants/constants'
import LogoRegistro from '../assets/icn_identificacion.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import lgMercantil from "../assets/icn_mercantil_blanco.png"
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nextpos.us/">
        NextPOS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(7),
  },
  submit: {
    color:"white",
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
    textTransform:"capitalize",
    width:"150px",
  },

  footerGrid: {
    width: "100%",
    position:"fixed",
    zIndex:"1000",
    bottom:"0",
    height: 50,
    paddingLeft:"10px",
    paddingRight:"10px",
    backgroundColor: "#EBEBEB",
  },

  buttonProgress: {
    color: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  gridCentral:{
    marginTop:"5%",
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
  },
  logoRegistroStyle:{

    width:"35px",
    height:"25px",
  },
  logoMercantilStyle:{
    width:"150px",
    height:"45px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function SignUp(props) {
  const classes = useStyles();
  const [idcommerce, setIdCommerce] = useState("")
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("")
  const [loader,setLoader]=useState(false)
  const [emptyIdCommerce,setEmptyIdCommerce] = useState(false)
  const [emptyMail,setEmptyMail] = useState(false)
  const [emptyPassword,setEmptyPassword]= useState(false)
  const [msg, setMsgs]= useState("")
  const [open,setOpen] =useState(false);
  const [onCheck,setOnChecked]=useState(false)
  const [register, setRegister] =useState(false)


  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };



  function handleClick(event){
        var isidEmpty = isEmpty(idcommerce,"id_commerce")
        var ismailEmpty = isEmpty(email,"mail")
        var isPassEmpty = isEmpty(password,"password")

        if(isidEmpty ||ismailEmpty || isPassEmpty){
          return
        }

        var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
        var apiBaseUrl =BaseURL;
        var payload={
          "id_commerce":idcommerce,
          "mail":email,
          "password":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
        axios.post(apiBaseUrl+addNewUserAdmin, payload)
        
        .then(function (response) {
              setLoader(false)
              if(response.data.Exito){
                //setMsgs(response.data.Message)
                props.history.push({
                  pathname: '/getStarted',
                  state: { user: email, password: cipherpass.toString()}
                })
                handleClickOpen()
              }
              else{   
                setMsgs(response.data.Message)
                handleClickOpen()
              }
              
        })
        .catch(function (error) {
          setLoader(false)
          setMsgs("Error en el servidor")
        });
  }

  function isEmpty(field,type){
    if(field===""){
      if(type === "id_commerce"){
        setEmptyIdCommerce(true)
        return true
      }else if(type === "mail"){
        setEmptyMail(true)
        return true
      }else if(type ==="password"){
        setEmptyPassword(true)
        return true
      }
      return true
    }else{
      if(type === "id_commerce"){
        setEmptyIdCommerce(false)
        return false
      }else if(type === "mail"){
        setEmptyMail(false)
        return false
      }else if( type ==="password"){
        setEmptyPassword(false)
        return false
      }
      return false
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    document.getElementById("idCommerce").value = ""
    document.getElementById("email").value = ""
    document.getElementById("password").value = ""
    setIdCommerce("")
    setEmail("")
    setPassword("")
  };
    return (
      <React.Fragment>

      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>
      

        <div className={classes.root}>
          <AppBar position="fixed" >
          <Toolbar style={{background :"linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"}}>
            <IconButton edge="start" color="inherit" className={classes.menuButton}  >
              {/* <MenuIcon /> */}
              <img src={lgMercantil} className={classes.logoMercantilStyle}></img>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            </Typography>
            <Button color="inherit"><label style={{fontSize:"18px",textTransform:"capitalize",fontFamily:"Roboto"}}>Portal Dpos</label></Button>
          </Toolbar>
        </AppBar>
        </div>
      
      
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
          
          <img src={LogoRegistro} className={classes.logoRegistroStyle}></img>
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Regístrate
          </Typography>

          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error = {emptyIdCommerce}
                  autoComplete="fname"
                  name="firstName"
                  
                  required
                  fullWidth
                  id="idCommerce"
                  label="Código de afiliación"
                  autoFocus
                  disabled = {loader}
                  inputProps={{maxLength: 6}}
                  onChange = {e => setIdCommerce(e.target.value)}
                />
               
                <Link href="https://www.mercantilbanco.com/mercprod/index.html" variant="body2" >
                ¿No tienes un código de afiliación?
                </Link>
                
                 
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  error = {emptyMail}
                  
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  disabled = {loader}
                  inputProps={{maxLength: 30}}
                  onChange = {e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>

                <TextField
                  error = {emptyPassword}
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  disabled = {loader}
                  inputProps={{maxLength:10}}
                  onChange = {e => setPassword(e.target.value)}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" onClick = {e=>setOnChecked(true)}/>}
                  label="Acepto las políticas de privacidad, términos y condiciones de la plataforma Dpos."
                />
              </Grid> */}

            </Grid>
            
            <Grid container className={classes.gridCentral}>
              <Grid item xs>
                  <Button
                  fullWidth  
                  color="white"
                  className={classes.submit}
                  disabled ={loader}
                  onClick={(event) => handleClick(event)}
                >
                  {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
                  Registrar
                </Button>
              </Grid>
            </Grid>
            

            <Grid container className={classes.gridCentral}>
              <Grid item xs={12}>
                <Link href="./login" variant="body2">
                ¿Ya te registraste? Inicia tu sesión
                </Link>
              </Grid>
            </Grid>

          </form>
        </div>
        {/* <Box mt={5}>
          <Copyright />
        </Box> */}

      <Grid container style={{display:"flex",flexDirection:"column",alignItems:"center"}} >
          <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
            {/* <Copyright /> */}
            <p style={{textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Copyright © Mercantil C.A., Banco Universal. RIF: J00002961-0.
            Todos los derechos reservados. {' '}{new Date().getFullYear()}{'.'} {"\n"}From NextPOS ®
            </p>            
          </Grid>
        </Grid>

      </Container>
      </React.Fragment>
    );
  
}
