import  React, { createContext } from "react";
import './App.css';
import Routes from './routes/routes'
import {Provider} from './contextProvider/context'

function App() {
 

  return (
    
        <Routes/>
    
        
    
    
      /*<BrowserRouter>
        <Switch>
          <Route path="/login" component={SignInSide} />
          <Route path="/home" component={Dashboard} />
        </Switch>
      </BrowserRouter>*/
      

    
    //<SignInSide/>
    //<Dashboard/>
  );
}

export default App;
