import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import login from '../assets/login_bm.jpg'

import {BaseURL, loginApi, MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fs} from 'fs';
import {path, dirname} from 'path';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import superagent from 'superagent'
import LogoUser from '../assets/icn_user.png'
import { Label } from '@material-ui/icons';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" justify="center">
     
      <Link style={{fontSize:"10px"}} color="inherit" href="https://nextpos.us">
      Copyright © Mercantil C.A., Banco Universal. RIF: J00002961-0.
      </Link>
   

      <Link style={{fontSize:"10px"}} color="inherit" href="https://nextpos.us">
        Todos los derechos reservados. {' '}
        {new Date().getFullYear()}
        {'.'} From NextPOS ®
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url('+login+')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'inherit',
    height:'100vh'
  },
  paper: {
    //margin: theme.spacing(8, 4),
    marginTop:"17%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    borderColor:"#007EC0",
    borderWidth:"2px",
    borderStyle:'solid',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
    textTransform:"capitalize",
    width:"150px",
  

  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    
  },
  logoUserStyle:{

    width:"25px",
    height:"25px",
  },

  gridBarButton:{
    marginTop:"3%"
  },
  footerGrid: {
    width: "100%",
    position:"fixed",
    zIndex:"1000",
    bottom:"0",
    height: 50,
    paddingLeft:"10px",
    paddingRight:"10px",
    backgroundColor: "#EBEBEB",
  }
  
}));


/*onkeypress = (event) =>{
  
  let username = document.getElementById("email")
  username.maxLength = 30;
      if ((event.keyCode < 64 || event.keyCode > 90) && (event.keyCode <97 || event.keyCode > 122)) {
        return false;
      }
    
}*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function SignInSide(props) {
  const classes = useStyles();
  let history = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [datauser, setDataUser] = useState({});
  const [open, setOpen] =useState(false);
  const [loader,setLoader]=useState(false)
  const [emptyMail,setEmptyMail] = useState(false)
  const [onCheck,setOnChecked]=useState(false)
  const [emptyPassword,setEmptyPassword]= useState(false)

  if(localStorage.getItem("_CDAUPOMGKLJL") !=null || sessionStorage.getItem("_CDAUPOMGKLJL")){
    history.push("/home");
  }

  function btnForgotPassword(event){
    history.push("/forgotpassword");
  }
      
  function handleClick(event){
        event.preventDefault()
        var ismailEmpty = isEmpty(email,"mail")
        var isPassEmpty = isEmpty(password,"password")
        //let keepSessionActived = document.getElementById("keepSession")

        if(ismailEmpty || isPassEmpty ){
          return
        }
        var ciphertext = CryptoJS.AES.encrypt(email, MasterKeyLogin);
        var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
        var apiBaseUrl =BaseURL;
        var payload={
          "Username":ciphertext.toString(),//"email.username",
          "Clave":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
        
        /*axios.post(apiBaseUrl+loginApi, payload)
        
        .then(function (response) {
          setLoader(false)
              if(response.data.exito){
                
                let id = response.data.data_usuario.id_usuario
                let rif = response.data.data_usuario.rif
                var cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                var cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                
                  if(keepSessionActived.checked==false){
                    sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                  }else{
                    localStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    localStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                  } 
                history.push("/home");
              }
              else{   
                handleClickOpen()
              }
              
        })
        .catch(function (error) {
          setLoader(false)
            console.log(error);
        });*/
       
        superagent
            .post(apiBaseUrl+loginApi)
            .send(payload) // sends a JSON post body
            .then(response =>{
              setLoader(false)
             
              if(response.body.exito){
                
                let id = response.body.data_usuario.id_usuario
                let rif = response.body.data_usuario.rif
                var cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                var cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                    sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                  /*if(keepSessionActived.checked==false){
                    sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                  }else{
                    localStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    localStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                  } */

                history.push("/home");
              }
              else{   
                handleClickOpen()
              }
            })
            .catch(error =>{
              setLoader(false)
              alert(error)
            })
  
  
  }

  function btnSignUp(event){
    history.push("/signup");
  }

  function isEmpty(field,type){
    if(field===""){
      if(type === "mail"){
        setEmptyMail(true)
        return true
      }else if(type ==="password"){
        setEmptyPassword(true)
        return true
      }
      return true
    }else{
      if(type === "mail"){
        setEmptyMail(false)
        return false
      }else if( type ==="password"){
        setEmptyPassword(false)
        return false
      }
      return false
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    
    <React.Fragment>
    
    <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Ingresa usuario o contraseña correcta
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Aceptar
            </Buttons>
          </DialogActions>
        </Dialog>


    <Grid container  component="main" className={classes.root} >
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}  square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {/* <LockOutlinedIcon /> */}
            <img src={LogoUser} className={classes.logoUserStyle}></img>
          </Avatar>
          <Typography component="h1" variant="h5" style={{marginTop:"3%"}}>
            Inicia tu sesión
          </Typography>
          <form className={classes.form} >

            <Grid container  alignItems="center" justify="center" >
              
              <Grid item xs={8} >
                <TextField
                  error = {emptyMail}
                  fullWidth
                  margin="normal"
                  color = "#1f2d3d"
                  required
                  id="email"
                  label="Correo Electrónico"
                  name="email"
                  onChange ={e => setEmail(e.target.value)}
                  disabled = {loader}
                  autoFocus
                  helperText={emptyMail?"Campo vacio":""}
                />
              </Grid>


            </Grid>

            <Grid container  alignItems="center" justify="center" >
                
              <Grid item xs={8}>
                <TextField
                  error = {emptyPassword}
                  fullWidth
                  margin="normal"
                  required
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  disabled = {loader}
                  onChange ={e => setPassword(e.target.value)}
                  helperText={emptyPassword?"Campo vacio":""}
                />
              </Grid>
            </Grid>
            
            
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" id= "keepSession" />}
              label="Mantener sesión activa"
            />*/}

            <Grid container  alignItems="center" justify="center" className={classes.gridBarButton} >                
              <Grid item xs={2.5} alignItems="center">
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled = {loader}
                onClick={(event) => handleClick(event)}
              >Ingresar
                {loader && <CircularProgress size={24} className={classes.buttonProgress} />}

              </Button>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justify="center" className={classes.gridBarButton}>

              <Grid item xs={4} style={{textAlign:"left"}}>
                <Link href="" onClick={(event) => btnForgotPassword(event)}  variant="body2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>

              <Grid item xs={4} style={{textAlign:"right"}} > 
                <Link a href="" onClick={(event) => btnSignUp(event)} variant="body2">
                  {"Regístrate aquí"}
                </Link>
              </Grid>

            </Grid>

            
          </form>
          

        </div>
        

        <Grid container justify="space-between" >
          <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
            {/* <Copyright /> */}
            <p style={{position:"fixed",textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Copyright © Mercantil C.A., Banco Universal. RIF: J00002961-0.
            Todos los derechos reservados. {' '}{new Date().getFullYear()}{'.'} {"\n"}From NextPOS ®
            </p>            
          </Grid>
        </Grid>



      </Grid>
    </Grid>
    
    </React.Fragment>

  );
}
