import React,{useContext} from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems, secondaryListItems } from './listItems';
import ListSecondaryItem from './componentsSPA/ListItem/ListSecondaryItem';
import { BrowserRouter as Router, Route } from "react-router-dom"
import Home from './componentsSPA/homeComponents/Home'
import Create from './componentsSPA/createComponents/Create'
import CryptoJS from 'crypto-js'
import {MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";
import lgMercantil from "../assets/icn_mercantil_blanco.png"
import Grid from '@material-ui/core/Grid';
import '../fonts/Roboto/Roboto-Bold.ttf' ;
function Copyright() { 
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        NextPOS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  logoMercantilStyle:{
    width:"150px",
    height:"41px",
    [theme.breakpoints.between('sm','md')]: {
      height:"20px",
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    background :"linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor:"#E0E0E0"
  },
  toolbarIconTitle:{
    position:"absolute",
    top:"0",
    left:"30%",
    right:"0",
    fontFamily:"Sans-serif"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:"#000048"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footerGrid: {
    width: "100%",
    position:"fixed",
    zIndex:"1000",
    bottom:"0",
    height: 50,
    paddingLeft:"10px",
    paddingRight:"10px",
    backgroundColor: "#EBEBEB",
  },
}));

export default function Dashboard() {
  let history = useHistory();
  let rif = localStorage.getItem("_CDAUPOMGKLJL") || sessionStorage.getItem("_CDAUPOMGKLJL")
  let id = localStorage.getItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKH")
  
  let defrif;
  let defid;
  /*validar sesion*/
  if(rif === null){
    history.push("/login")
  }else{
    let decryptRif = CryptoJS.AES.decrypt(rif, MasterKeyLogin)
    defrif = decryptRif.toString(CryptoJS.enc.Utf8);
    let decryptid = CryptoJS.AES.decrypt(id, MasterKeyLogin)
    defid = decryptid.toString(CryptoJS.enc.Utf8);
  }

  
  const exitButton = withStyles(theme => ({
    root: {
      borderRadius:'20px',
      border:'2px  solid #1f2d3d ',
      marginTop: '30px',
      marginLeft: '20px',
      color:"#1f2d3d", 
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#000048',
        color:"white"
      },

    },
  }))(Button);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = event => {
    event.preventDefault();
    setOpen(true);
  };

  const handleDrawerClose = event => {
    event.preventDefault();
    setOpen(false);
  };

  const logout = event => {
    
    localStorage.removeItem("_CDAUPOMGKLJL") || sessionStorage.removeItem("_CDAUPOMGKLJL")
    localStorage.removeItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.removeItem("_CDAUPOMGKLJLTYJIKH")
    window.location.reload();
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
 
  return (
    <div className={classes.root}>
      <CssBaseline />

    

      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
         
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
           
          >
           
            <MenuIcon/>
          </IconButton>
          <img src={lgMercantil} className={classes.logoMercantilStyle}></img>
         <Typography variant="h6" className={classes.title}>
            </Typography>
            <label style={{fontSize:"18px",textTransform:"capitalize",}}>Portal Dpos</label>
          {/* <Tooltip title = "cerrar sesión">
              <IconButton color="inherit" onClick={logout} type = "submit">
                
                <ExitToAppIcon  />
            
              </IconButton>
          </Tooltip> */}
          
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
         
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>
          <ListSecondaryItem/>
        </List>
        
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Route
            path='/home'
            render={(props) =><Home {...props}  rif = {defrif}/> }
          />
          <Route
            path='/create'
            render={(props) =><Create {...props}  rif = {defrif}  id = {defid}/> }
          />
          
          {/*<Grid container spacing={3}>
           
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits rif = {defrif}/>
              </Paper>
            </Grid>
            
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders rif = {defrif} />
              </Paper>
            </Grid>
      </Grid>*/}
          <Grid container style={{display:"flex",flexDirection:"column",alignItems:"center"}} >
          <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
            {/* <Copyright /> */}
            <p style={{textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Copyright © Mercantil C.A., Banco Universal. RIF: J00002961-0.
            Todos los derechos reservados. {' '}{new Date().getFullYear()}{'.'} {"\n"}From NextPOS ®
            </p>            
          </Grid>
        </Grid>
        </Container>
      </main>
    </div>
  );
}
