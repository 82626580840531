import React , {useEffect,useState} from 'react';
import { Pie } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divContainer:{
    marginTop:-30,
   
  }

}));
	
	export default function PieComponent(props) {
	 const [data,setData]=useState()
   const classes = useStyles();
   useEffect(()=>{

      console.log(props)
      const dat = {
        labels: [props.datos[0].name+ " Bs", props.datos[1].name  +" Bs", props.datos[2].name  + " Bs"],
        datasets: [
          {
           
            data: [props.datos[0].value, props.datos[1].value, props.datos[2].value],
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
             
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              
            ],
            borderWidth: 1,
            
          },
        ],
      };

      setData(dat)
    },[])
	 
	  return (

        	<div className={classes.divContainer}>
            {data !== undefined && console.log(data)}

			      {data !== undefined &&	<Pie data={data} width={500} height={200} options={{
maintainAspectRatio: false
}} />}
            </div>

	  );

	}