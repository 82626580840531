import React,{useState}from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(3),
		fontSize:10
	  },

}));
export default function ListSecondaryItem() {
	const classes = useStyles();	 
		const logout = event => {
    
			localStorage.removeItem("_CDAUPOMGKLJL") || sessionStorage.removeItem("_CDAUPOMGKLJL")
			localStorage.removeItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.removeItem("_CDAUPOMGKLJLTYJIKH")
			window.location.reload();
		  };
		  
		  const [open,setOpen] = useState(false)
		  
			const handleClick = () => {
			  setOpen(!open)
			};
	 
	  return (

        <div>

<ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FindInPageOutlinedIcon  style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Reportes de ventas" />
      {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      
    </ListItem>
	<Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText secondary="Reporte detalles transacciones" />
          </ListItem>
        </List>

		<List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText secondary="Reporte diario de ventas" style={{fontSize:10}} />
          </ListItem>
        </List>
      </Collapse>
    <ListItem button component = {Link} to="/">
      <ListItemIcon>
        <LibraryBooksOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Instructivos" />
    </ListItem>
	
    <ListItem button component = {Link} to="/">
      <ListItemIcon>
        <HelpOutlineOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Ayuda" />
    </ListItem>
    <ListItem button onClick={logout} type="submit">
      <ListItemIcon>
        <ExitToAppOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Cerrar sesión" />
    </ListItem>
    

            </div>

	  );

	}