
export const  BaseURL = "https://api.nextpos.us/Usuarios/"//"https://10.0.1.30:443/Usuarios/";//////"https://52.67.137.250:443/Usuarios/" //"http://127.0.0.1:3000/Usuarios/"
export const  getAllTransactions = "getTodasTransaccionesEmpresasDashboard?rif="
export const  updatePasswordAdmin = "updatePassword"
export const  addUserDPOS = "addUserDPOS"
export const  sendMailAdmin = "sendMailAdmin"
export const  getAllUserDPOS = "getAllUserDPOS"
export const  addNewUserAdmin = "addNewUserAdmin"
export const  loginApi = "login_admin"
export const  SumTrxApi = "sum_trx"
export const  MasterKeyLogin = "l061nu53r"
export const  SumTrxDate = "sum_trx_to_date"
export const  getTransactionsByDate = "getTransaccionesFecha"
export const  getTrxMonth = "get_transacciones_mes"
