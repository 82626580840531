import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import {BaseURL, sendMailAdmin, MasterKeyLogin} from '../constants/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import icnLock from '../assets/icn_lock.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import lgMercantil from "../assets/icn_mercantil_blanco.png"

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://nextpos.us/">
          NextPOS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
    paper: {
      marginTop: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "white",
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: "15%",
    },
    submit: {
      color:"white",
      margin: theme.spacing(3, 0, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
      textTransform:"capitalize",
      width:"150px",
    },
      buttonProgress: {
        color: "#1f2d3d",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      logoLockStyle:{

        width:"25px",
        height:"25px",
      },
      gridCentral:{
        marginTop:"5%",
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
      },
      footerGrid: {
        width: "100%",
        position:"fixed",
        zIndex:"1000",
        bottom:"0",
        height: 50,
        paddingLeft:"10px",
        paddingRight:"10px",
        backgroundColor: "#EBEBEB",
      },
      logoMercantilStyle:{
        width:"150px",
        height:"45px",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
    export default function ForgotPassword(){
        const classes = useStyles();
        let history = useHistory();
        const [email, setEmail] = useState("")
        const [emptymail, setEmptyMail] = useState(false)
        const [loader,setLoader] = useState(false)
        const [onCheck,setOnChecked]=useState(false)
        const [open, setOpen] =useState(false);
        const [msg, setMsgs]= useState("")

        function resetPassword(event){
          
          event.preventDefault()
          var ismailEmpty = isEmpty(email)
          if(ismailEmpty){
            return
          }
          setLoader(true)
          var payload= {
            mail: email
          }
          axios.post(BaseURL+sendMailAdmin, payload)
          .then(function (response) {
            setLoader(false)
                if(response.data.Exito){
                  setMsgs(response.data.Message)
                  
                  handleClickOpen()
                }
                else{   
                  setMsgs(response.data.Message)
                  handleClickOpen()
                }
               
          })
          .catch(function (error) {
            setLoader(false)
            setMsgs("Error en el servidor")
          });
            //history.push("/resetpassword");
        }
        function isEmpty(field){
          if(field===""){
            setEmptyMail(true)
            return true
          }else{
            setEmptyMail(false)
            return false
          }
        }

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
          document.getElementById("email").value = ""
        };
    return (
      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Aceptar
            </Buttons>
          </DialogActions>
        </Dialog>
        <Container component="main" maxWidth="xs">
        <CssBaseline />

        <div className={classes.root}>
          <AppBar position="fixed" >
          <Toolbar style={{background :"linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"}}>
            <IconButton edge="start" color="inherit" className={classes.menuButton}  >
              {/* <MenuIcon /> */}
              <img src={lgMercantil} className={classes.logoMercantilStyle}></img>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            </Typography>
            <Button color="inherit"><label style={{fontSize:"18px",textTransform:"capitalize",fontFamily:"Roboto"}}>Portal Dpos</label></Button>
          </Toolbar>
        </AppBar>
        </div>

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {/* <LockOutlinedIcon /> */}
            <img src={icnLock} className={classes.logoLockStyle}></img>
          </Avatar>
          <Typography component="h1" variant="h5">
            Recupera tu contraseña
          </Typography>

        <form className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
                    <TextField
                    error = {emptymail}
                    required
                    fullWidth
                    id="email"
                    label="Correo electrónico"
                    value={email}
                    name="email"
                    autoComplete="email"
                    disabled = {loader}
                    inputProps={{maxLength: 30}}
                    onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                
            </Grid>

            <Grid container className={classes.gridCentral}>
              <Grid item xs>
                  <Button
                  type="button"
                  fullWidth  
                  color="white"
                  className={classes.submit}
                  disabled ={loader}
                  onClick={(event) => resetPassword(event)}
                >
                  {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
                  Enviar
                </Button>
              </Grid>
            </Grid>

            {/* <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled = {loader}
              onClick={(event) => resetPassword(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Enviar
            </Button> */}

        </form>
        </div>
        {/* <Box mt={5}>
          <Copyright />
        </Box> */}

        <Grid container style={{display:"flex",flexDirection:"column",alignItems:"center"}} >
          <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
            {/* <Copyright /> */}
            <p style={{textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Copyright © Mercantil C.A., Banco Universal. RIF: J00002961-0.
            Todos los derechos reservados. {' '}{new Date().getFullYear()}{'.'} {"\n"}From NextPOS ®
            </p>            
          </Grid>
        </Grid>

        </Container>
      </React.Fragment>
       
    );
}
