import React ,{useState, useEffect}from 'react';
import { ResponsiveContainer,PieChart, Pie, Sector, Cell,  } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import {useHistory} from 'react-router-dom'
//button custom
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import {BaseURL, SumTrxApi,getAllTransactions, MasterKeyLogin} from '../../../../constants/constants'

import PieComponent from '../../Charts/PieComponent';
const useStyles = makeStyles(theme => ({
        resume: {
            fontFamily:"Roboto-Bold",
            fontStyle:"bold",
            fontSize: "large",
            paddingLeft: "5mm",
            marginTop : "5mm",
            marginInline: "initial",
            color:"#06B0EF"         
        },
        amount:{
            fontFamily:"Roboto-Bold",
            fontStyle:"bold",
            fontSize: "x-large",
            paddingLeft: "5mm",
            marginTop : "5mm",
            marginInline: "initial",
            color:"#004E9B"         
        },
        total_trx_aproved: {
            fontFamily:"Roboto-Bold",
            fontStyle:"bold",
            fontSize: "medium",
            paddingLeft: "5mm",
            marginTop:"1mm",
            marginInline: "initial",
            color:"#a7a7a7"
        }

    }));
export default function Resumen(props) {
  const classes = useStyles();
  let history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  /* 
  
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isRetina = useMediaQuery({ minResolution: '2dppx' })
  
  */
  let rifMerchant = props.rif
  let payload={
      "rif":rifMerchant    
  }

  let totalAmount

  const[loaderAmount,setLoaderAmount]= useState(false)
  const[loaderDataTrx,setLoaderDataTrx]= useState(false)
  const[totalTrxAmount,setTotalTrxAmount]= useState(0)
  const[dataPieChart,setDataPieChart]= useState([
     
        { name: 'Debito', value: 0 },
        { name: 'Credito', value: 0 },
        { name: 'C2P', value: 0 },
        
      
    ])
   
  const [totalTrxAproved, setTotalTrxAproved]= useState(0)
  

  useEffect((props) => {
    getTotalTrxBs()
    getTotalTrxData()
  },[])

  const getTotalTrxBs =  ()=> {
    let wrap = document.getElementById("wrap")
    wrap.style.opacity = 0.5
    setLoaderAmount(true)
     axios.post(BaseURL+SumTrxApi,payload)
    .then(res => {
      wrap.style.opacity = 1
      setLoaderAmount(false)
      console.log(res)
      if(res.data.Exito){
        totalAmount = res.data.Sum
      }else{
        totalAmount = 0.00
      }
      setTotalTrxAmount(totalAmount)
      
        
    })
    .catch(err=>{
      wrap.style.opacity = 1
      setLoaderAmount(false)
      console.log(err)
    })
  }

  const getTotalTrxData =()=> {
    let totalAproved = 0
    let totalDebit = 0
    let totalCredit =0 
    let totalC2P=0
    
    setLoaderDataTrx(true)
    axios.get(BaseURL+getAllTransactions+rifMerchant)
    
    .then(res => {
        console.log(res)
        setLoaderDataTrx(false)
        if (res.data[0] != undefined) {
           let datos  = res.data
           
           datos.forEach(function(e){
            if(e.estatus === 1){
              setTotalTrxAproved(totalAproved++)
              if(e.tipo_tarjeta === 'Maestro'){
                totalDebit = parseFloat(totalDebit)+parseFloat(e.monto) 
            
                
              }else if(e.tipo_tarjeta === 'Visa' || e.tipo_tarjeta === 'Mastercard'||e.tipo_tarjeta === 'Dinner Club' ){
                totalCredit=parseFloat(totalCredit)+parseFloat(e.monto) 
               
              }else if(e.tipo_tarjeta === 'C2P Compra'){
                totalC2P=parseFloat(totalC2P)+parseFloat(e.monto) 
                
              }
              
              
            }
           })

          

        setDataPieChart([
            {"name":"Debito", "value":totalDebit},
            {"name":"Credito", "value":totalCredit},
            {"name":"C2P", "value":totalC2P}
        ])
           
           
        } 
  
    })
    .catch(err=>{
        setLoaderDataTrx(false)
      console.log(err)
    })
  }


  const onClickCapture = (e)=>{
     e.preventDefault()
     history.push('/create')
  }
 

  

  
  return (
    <React.Fragment>
      {/* <Title>Today</Title> */}

      {console.log(dataPieChart)}
      <ResponsiveContainer width="100%" height="100%" id = "wrap" >
        <Container>
          <Row style={{marginTop:"10mm"}}> 
              <Col>
                  <Row>
                    <div>
                        <label className={classes.resume}>Resumen transacciones</label>
                    </div>
                  </Row >
                  <Row>
                  { loaderAmount ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<label className={classes.amount} >Bs {new Intl.NumberFormat("de-DE").format(totalTrxAmount)}</label>}
                    
                  </Row>
                  <Row>
                  { loaderDataTrx ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<label className={classes.total_trx_aproved} >{totalTrxAproved} transacciones aprobadas</label>}

                  
                  </Row>
              </Col>

              <Col>
                    <Row style={{paddingLeft:"50mm"}}>
                       
                        
                         {dataPieChart[0].value !==0 && dataPieChart[1].value!==0 &&dataPieChart[2].value!==0 && !loaderDataTrx?<PieComponent datos={dataPieChart}/>:<CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" /> }   
                        
                    </Row>
              </Col>

          </Row>
        </Container>

      </ResponsiveContainer>
    </React.Fragment>
  );
}