import React , { useState }from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import home from '../assets/home.png';
import check from '../assets/check.png'
import {BaseURL, loginApi, MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nextpos.us/">
        NextPOS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  container:{
   
    marginTop: '10px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  home:{
    width:"100px"
  },
  check:{
    marginTop:"25px",
    width: "40px"
  },
  enrrolled:{
    color:'gray',
    marginTop:'20px'

  },
  shopName:{
    //marginTop:'20px',
    fontWeight: 'bold',
    fontSize:'20px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    
    backgroundColor : "#000048",
    marginTop:'80px',
    marginBottom:'55px'

  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

export default function GetStarted(props) {
  let history = useHistory();
  let user;
  let password;
  const classes = useStyles();

  const [loader,setLoader]=useState(false)
 
  
  if(props.location.state.user === undefined || props.location.state.user === '' || props.location.state.password ===undefined || props.location.state.password ===''){
    history.push("/login")
  }else{
    user = props.location.state.user
    password = props.location.state.password
  }

  function handleClick(event){
       
    event.preventDefault()

        
        var ciphertext = CryptoJS.AES.encrypt(user, MasterKeyLogin);
        var cipherpass = password;
        var apiBaseUrl =BaseURL;
        var payload={
          "Username":ciphertext.toString(),//"email.username",
          "Clave":cipherpass//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
        console.log(payload)
        axios.post(apiBaseUrl+loginApi, payload)
        
        .then(function (response) {
              setLoader(false)
              if(response.data.exito){
                //setMsgs(response.data.Message)
                //handleClickOpen()
                
                let id = response.data.data_usuario.id_usuario
                let rif = response.data.data_usuario.rif
                
                var cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                var cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                history.push("/home");
              }
              else{   
                history.push("/login")
              }
              
        })
        .catch(function (error) {
          setLoader(false)
          console.log(error)
          //setMsgs("Error en el servidor")
        });
  }

    return (
      <React.Fragment>
      
      <Container className = {classes.container } component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
           
            <img src={home} className={classes.home} alt="home"  />
          
            <img src = {check} className={classes.check} alt = "check"/>
          <Typography component="subtitle1" variant="subtitle1" className={classes.enrrolled}>
            Su comercio ha sido registrado exitosamente
          </Typography>
          <Typography component="subtitle1" variant="subtitle1" className={classes.shopName}>
            {user}
          </Typography>
          <form className={classes.form} noValidate>
            <Button
              
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled ={loader}
              onClick={(event) => handleClick(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
             Comenzar
            </Button>
            
          </form>
        </div>
        
      </Container>

      <Box mt={5}>
          <Copyright />
        </Box>
      </React.Fragment>
    );
  
}
