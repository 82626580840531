import React from 'react';
import { ResponsiveContainer,LineChart,CartesianGrid,XAxis,YAxis,Tooltip,Line } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import { Chart } from "react-google-charts";
import {BaseURL, getTrxMonth , MasterKeyLogin} from '../../../constants/constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  titleChart:{
    fontWeight:'bold'
  }
}))

export default function Graph(props) {
    
    const classes = useStyles();
    let rifMerchant = props.rif
    let rif = rifMerchant

    let apiBaseUrl =BaseURL;
    let payload={
      "rif":rif    
    }
    const [datachar, setStateDataChart]=React.useState({
        chartData: [],
      })
   
    React.useEffect((props) => {
    
        axios.get(apiBaseUrl+getTrxMonth+"?rif="+rif)
        .then(res => {
          //totalAmount = res.data.Sum
          //setStateTable({data:totalAmount})
          const data = res.data.message
          console.log(data)
          setStateDataChart({chartData:data})
          
         
          
         
        })
        .catch(err=>{
          console.log(err)
        })
      
      },[])
    return (
        <React.Fragment>
          {}
          <ResponsiveContainer width={700} height="80%">
            <Container>
              <Typography className= {classes.titleChart}>
                GRÁFICO DE OPERACIONES
              </Typography>
              <LineChart width={1010} height={200}  data={datachar.chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"MONTH"} />
                <YAxis />
                <Tooltip />
                
                <Line type="monotone" dataKey="numRecords" stroke="#8884d8" />
                <Line type="monotone" dataKey="sumTrx" stroke="#82ca9d" />
              </LineChart>
            </Container>
          </ResponsiveContainer>
        </React.Fragment>
      );
}