import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from './Chart';
import Graph from './Graph';
import Deposits from './Deposits';
import Orders from './Orders';
import Resumen from './ResumenSection.js/Resumen';


const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        overflow: 'auto',
      },
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Home (props){
    
    let defrif = props.rif
   
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
 
  
    return(
      <Grid container spacing={3}>
        {/* RESUMENT TRX  */}
        <Grid item xs={12} md={12} lg={12}>
        <Paper className={fixedHeightPaper}>
          <Resumen rif = {defrif}/>
        </Paper>
      </Grid>
      {/* Gestionar usuarios */}
      {/* <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <Chart />
        </Paper>
      </Grid> */}
      {/* Total trx */}
      {/* <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Deposits rif = {defrif}/>
        </Paper>
      </Grid> */}
      {/* Charts */}
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Graph rif = {defrif} />
        </Paper>
      </Grid>
      
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Orders rif = {defrif} />
        </Paper>
      </Grid>
    </Grid>
  ) 
}  