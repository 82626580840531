import React, { useState, useEffect,forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import TextField from '@material-ui/core/TextField';

//import UserRegister from './UserRegister';
import { ResponsiveContainer } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
//import RegisterUser from './RegisterUser';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {  withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive'

import Slide from '@material-ui/core/Slide';
import {BaseURL,getAllUserDPOS,addUserDPOS,MasterKeyLogin} from '../../../constants/constants'
import axios from 'axios';
import { purple } from '@material-ui/core/colors';
import CryptoJS from 'crypto-js' 
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
//import { green } from '@material-ui/core/colors';
//const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    root:{
      width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    fixedHeightUser: {
      height: 300,
    },
    alert:{
      backgroundColor: 'green'
    },
    successText:{
      color:'green'
    },
    pendingText:{
      color:'orange'
    },
    rejectText:{
      color : 'red'
    },
    title_principal:{

      color: "#1a80e6",
      marginTop:"3%",
      marginLeft:"5%",
      fontSize: "18px",
    },
    title_usuario:{
      color: "#1a80e6",
      marginTop:"2%",
      marginBottom:"1%",
      marginLeft:"3%",
      fontSize: "18px",
    },
    title_detalle:{
      
      paddingTop:"2%",
      marginLeft:"5%",
      fontSize: "15px",
    },
    btn_crear_user:{
      paddingTop:"2%",
      marginLeft:"5%",
    },
    txtUser:{
      //paddingTop:"2%",
      marginLeft:"3%",
    },
    submit: {
      color:"white",
      margin: theme.spacing(3, 4, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
      textTransform:"capitalize",
      width:"150px",
      //marginLeft:"2.5%",
    },
  }));

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}



export default function Create (props){
    
    let defrif = props.rif
    let deid = props.id
    
    const [open,setOpen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [success,setSuccess] = useState(false)
    const [loader,setLoader] = useState(false)
    const [table, setStateTable]=React.useState({
      data: [
        //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
        //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
        
      ],
  
    })
    const [add, setAdd] =useState(false)
    const[update,setUpdate] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emptyMail,setEmptyMail] = useState(false)
    const [emptyPassword,setEmptyPassword]= useState(false)
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightPaperUser = clsx(classes.paper, classes.fixedHeightUser);
    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-device-width: 1224px)'
    })
    const handleClickOpen = (e) => {
      e.preventDefault()
      if(!open){
        setOpen(true)
      }else{
        setOpen(false)
      }
    };

    React.useEffect(() => {
      
        let id = deid
        let payload = {
          id_user_admin : id
        }
        
        
        axios.post(BaseURL+getAllUserDPOS,payload)
        
        .then(res => {
            setLoader(false)
            
            if (res.data[0] != undefined) {
               let datos  = res.data
               
               datos.forEach(function(e){
                e["app"] = "DPOS"
                if(e.estatus === 1){
                  e["estatus"] = <a className ={classes.successText}>
                                        Aprobado
                                 </a>
                }else if (e.estatus ===2){

                  e["estatus"] = <Tooltip title="En espera por aprobación del banco" aria-label="add">
                                    <a className ={classes.pendingText}>
                                      Pendiente
                                    </a>   
                                  </Tooltip>
                }else{
                  e["estatus"] = <a className ={classes.rejectText}>
                                   Rechazado
                                </a>
                }
               
              })

             
               setStateTable({data:datos})
                return res.data;
              } else {
                
                return 'Null';
              }
              
        })
        .catch(err=>{
          setLoader(false)
          console.log(err)
        })
    
    },[update])
    /*const handleToUpdate = () => {
        setLoader(true)
    }*/

    function isEmpty(field,type){
      if(field===""){
        if(type === "mail"){
          setEmptyMail(true)
          return true
        }else if(type ==="password"){
          setEmptyPassword(true)
          return true
        }
        return true
      }else{
        if(type === "mail"){
          setEmptyMail(false)
          return false
        }else if( type ==="password"){
          setEmptyPassword(false)
          return false
        }
        return false
      }
    }
    
    function handleToUpdate(){
       
        setLoader(true)
        
    } 
      
    const ColorButton = withStyles(theme => ({
        root: {
          borderRadius:'20px',
          border:'2px  solid #1f2d3d ',
          marginTop: '50px',
          
          color:"#1f2d3d", 
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: '#000048',
            color:"white"
          },
  
        },
    }))(Button);
  
    const handleClickSend = (e) => {
          e.preventDefault()
          
          
          var ismailEmpty = isEmpty(email,"mail")
          var isPassEmpty = isEmpty(password,"password")
  
          if(ismailEmpty || isPassEmpty){
            return
          }

          var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
          var apiBaseUrl =BaseURL;
          
          
          var payload={
            "id_user_admin":deid,
            "user":email,
            "password":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
          }
          setLoader(true)
          axios.post(apiBaseUrl+addUserDPOS, payload)
          
          .then(function (response) {
            
                if(response.data.Exito===true){
                  setUpdate(true)
                  setLoader(false)
                  setUpdate(false)
                  setOpenAlert(true)
                  setSuccess(true)
                }
                else{
                  setUpdate(false)
                  setLoader(false)   
                  setOpenAlert(true)
                  setSuccess(false)
                 console.log(response)
                }
                
          })
          .catch(function (error) {
              setUpdate(false)
              setLoader(false) 
              setOpenAlert(true)
              setSuccess(false)
              console.log(error);
          });
    };
    
    const handleCloseAlert = () =>{
      setOpenAlert(false)
    };

    const ColorButtonRegister = withStyles(theme => ({
      root: {
        marginTop: '10px',
          marginLeft: '20px',
          color: theme.palette.getContrastText(purple[500]),
          backgroundColor: '#000048',
          '&:hover': {
            backgroundColor: '#293990',//293990
          },
          
        },

      
    }))(Button);
    
    return(
          <React.Fragment>
            <div className = {classes.root}>
            {success ? 

                <Snackbar  open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} TransitionComponent={TransitionRight} anchorOrigin={{vertical:"bottom", horizontal: "left" }} >
                  <Alert variant="filled" severity="success">Usuario registrado con éxito!</Alert>
                </Snackbar>
                :
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} TransitionComponent={TransitionRight} anchorOrigin={{vertical:"bottom", horizontal: "left" }}>
                  <Alert variant="filled" severity="error">Error al registrar usuario!</Alert>
                </Snackbar>
            }
            </div>
           
          
         
          <Grid container spacing={3}>
            {/* Chart */} 
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <ResponsiveContainer>
                    <Container >
                    <Row>
                        <Col>
                            <Row>
                              
                              <label className={classes.title_principal}>Gestionar Usuarios</label>
                            </Row >
                            <Row>
                              <label className={classes.title_detalle}>En esta sección podrás gestionar de forma segura el acceso a tu Dpos</label>
                            </Row>
                            <Row>
                              <ColorButton className={classes.btn_crear_user}  variant = "outlined" onClick = {handleClickOpen}  >
                                + Crear Usuario 
                              </ColorButton>
                            </Row>
                        </Col>
                        
                            {isDesktopOrLaptop &&
                            <Col>
                            
                                

                            </Col>
                            }
                    </Row>
                  </Container>
    
                </ResponsiveContainer> 
              </Paper>
            </Grid>
            {
              open &&
              <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaperUser}>
                {/*<RegisterUser  handleToUpdate = {()=>handleToUpdate}/>*/}
                 <ResponsiveContainer>
                    <Container >
                      <Row>
                          <Col>
                              <Row>
                                <label className={classes.title_usuario} >Usuario Dpos</label>
                              </Row >
                            
                            <Row>
                                <TextField
                                    error = {emptyMail}
                                    variant="outlined"
                                    margin="normal"
                                    
                                    required
                                    size = "small"
                                    id="email"
                                    label="Usuario"
                                    name="email"
                                    onChange ={e => setEmail(e.target.value)}
                                    //disabled = {loader}
                                    autoFocus
                                    className={classes.txtUser}
                                />
                              
                            </Row>

                            <Row>
                                <TextField
                                    error = {emptyPassword}
                                    variant="outlined"
                                    margin="normal"
                                  
                                    required
                                    size = "small"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    onChange ={e => setPassword(e.target.value)}
                                    //disabled = {loader}
                                    
                                    className={classes.txtUser}
                        
                                />
                              
                            </Row>
                            <Row>

                            
                                    <Button
                                    fullWidth  
                                    color="white"
                                    className={classes.submit}
                                    disabled ={loader}
                                    onClick= {handleClickSend}
                                  >
                                    Crear
                                  </Button>
                              

                            {/* <ColorButtonRegister  className={classes.txtUser} variant="contained" color="primary" onClick= {handleClickSend}>
                                Registrar usuario
                             </ColorButtonRegister> */}
                            </Row>

                            
                            
                        </Col>
                    
                            
                            
                        
                    </Row>
                  </Container>
          
                </ResponsiveContainer>
              </Paper>
            </Grid> 
              
            }
           
            
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                
                <MaterialTable
                    title="Usuarios"
                    isLoading = {loader}
                    columns={
                                          
                      [
                        { title: 'Fecha', field: 'fecha' },
                        { title: 'Hora', field: 'fecha'},
                        { title: 'Usuario', field: 'username' },
                        { title: 'Estatus', field: 'estatus'},
                        
                      ]
                      
                    }
                   
                    data={table.data}

                    actions={[
                      
                      rowData => ({
                        icon: 'delete',
                        tooltip: 'Delete User',
                        //onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
                        //disabled: rowData.birthYear < 2000
                      })
                    ]}

                    options={{
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#FFF',
                        color: '#1f2d3d'
                      }
                    }}
            
                    
                  >
                </MaterialTable>
              </Paper>
            </Grid>
          </Grid>
          </React.Fragment>
           )
      
} 